import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { catchError } from 'rxjs/operators'
import { BehaviorSubject, throwError } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  base_url = 'https://fbint.azurewebsites.net/api/'
  base_url2 = 'https://localhost:44373/api/'
  ecom_urllive = 'https://fbecom.azurewebsites.net/api/'
  base_url1 = 'https://biz1retail.azurewebsites.net/api/'
  base_url3 = 'https://retailpos.azurewebsites.net/api/'
  base_url4 = 'https://biz1ps.azurewebsites.net/api/'
  constructor(private http: HttpClient) {}
  public storeid: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  public contactid: BehaviorSubject<number> = new BehaviorSubject<number>(0)

  toFormData(formValue) {
    const formData = new FormData()
    for (const key of Object.keys(formValue)) {
      const value = formValue[key]
      // console.log(key, value)
      formData.append(key, value)
    }
    return formData
  }

  phpayDet(pid) {
    return this.http.post(
      'https://biz1pos.azurewebsites.net/api/PhonePe/TransactionStatusCheck?merchantId=FBCAKESONLINE&merchantTransactionId=' +
        pid,
      {},
    )
  }

  updateSort(spgId, sortID) {
    return this.http.get(
      this.ecom_urllive + 'OrderDashboard/_updateProd_Sort?SPGid=' + spgId + '&sortID=' + sortID,
    )
  }

  updateCustRes(id, msg) {
    return this.http.get(this.ecom_urllive + 'OrderDashboard/CustNoOrd?cid=' + id + '+&msg=' + msg)
  }

  imgUPLOAD(pid, image) {
    const body = new FormData()
    body.append('file', image)
    return this.http.post(this.ecom_urllive + 'Ecommerce/_imgUpload?pid=' + pid, body)
  }

  //--- GOPI_ START
  // ecom --------------------------------------
  getOrdersFB() {
    return this.http.get(this.ecom_urllive + 'OrderDashboard/getDOrders_new')
  }

  addCompleme(id) {
    return this.http.get(this.ecom_urllive + 'OrderDashboard/addComplementry?oid=' + id)
  }

  sendCoopen(id) {
    return this.http.post(this.ecom_urllive + 'OrderDashboard/addCusCop?cid=' + id, {})
  }

  getProdREP(sdate, edate) {
    return this.http.get(
      this.ecom_urllive + 'OrderDashboard/getProductReport?sd=' + sdate + '&ed=' + edate,
    )
  }

  getOrdersFBbyId(oid) {
    return this.http.get(this.ecom_urllive + 'OrderDashboard/getOrderbyId?oid=' + oid)
  }

  getAllStores() {
    return this.http.get(this.ecom_urllive + 'OrderDashboard/getAllStore')
  }

  updateStatus(oid, osid, storId, res) {
    return this.http.get(
      this.ecom_urllive +
        'OrderDashboard/pushOrder?oid=' +
        oid +
        '&osid=' +
        osid +
        '&storeId=' +
        storId +
        '&note=' +
        res,
    )
  }

  updateStatus2(oid, osid, storId) {
    return this.http.get(
      this.ecom_urllive +
        'OrderDashboard/pushOrder2?oid=' +
        oid +
        '&osid=' +
        osid +
        '&storeId=' +
        storId,
    )
  }

  editOImsg(oiId, msg) {
    return this.http.get(
      this.ecom_urllive + 'OrderDashboard/editcakeMSG?oiId=' + oiId + '&msg=' + msg,
    )
  }

  editOnote(oId, note) {
    return this.http.get(
      this.ecom_urllive + 'OrderDashboard/editOrderNote?oid=' + oId + '&note=' + note,
    )
  }

  editDeliverytime(oId, dtime) {
    return this.http.get(
      this.ecom_urllive + 'OrderDashboard/editDdate?oid=' + oId + '&d_date=' + dtime,
    )
  }

  editDeliveryAddress(caId, dAddress) {
    return this.http.get(
      this.ecom_urllive + 'OrderDashboard/editCAid?caid=' + caId + '&address=' + dAddress,
    )
  }

  getCustList() {
    return this.http.get(this.ecom_urllive + 'OrderDashboard/getCustList')
  }

  _getCustList(ph) {
    return this.http.get(this.ecom_urllive + 'OrderDashboard/getCustList?ph=' + ph)
  }

  changeCOD(oid) {
    return this.http.get(this.ecom_urllive + 'OrderDashboard/changeCOD?oid=' + oid)
  }

  changeOSI(oid, osi) {
    return this.http.get(this.ecom_urllive + 'OrderDashboard/changeOSI?oid=' + oid + '&osi=' + osi)
  }

  changeOI_Qty(oIid, qty) {
    return this.http.get(
      this.ecom_urllive + 'OrderDashboard/changeOrditemQTY?orditem_id=' + oIid + '&kg=' + qty,
    )
  }

  orderRep(sdate, edate) {
    return this.http.get(
      this.ecom_urllive + 'OrderDashboard/getOrders?sd=' + sdate + '&ed=' + edate,
    )
  }

  getCustENQ() {
    return this.http.get(this.ecom_urllive + 'OrderDashboard/getcustenquiry')
  }

  updateCustEnq(id, rep_msg) {
    return this.http.get(
      this.ecom_urllive + 'OrderDashboard/updateContactForm?id=' + id + '&re_msg=' + rep_msg,
    )
  }

  // ecom
  getProdList_R() {
    return this.http.get(this.ecom_urllive + 'OrderDashboard/getProd_R')
  }
  getpredef_R(pid) {
    return this.http.get(this.ecom_urllive + 'OrderDashboard/getPre_Prod_R?p_id=' + pid)
  }
  updatePROIMG_R(pid, imgurl) {
    return this.http.get(
      this.ecom_urllive + 'OrderDashboard/updateIMGURL_R?pid=' + pid + '&url=' + imgurl,
    )
  }
  updatePROAMT_R(pid, amt, isonline) {
    return this.http.get(
      this.ecom_urllive +
        'OrderDashboard/updatePreDefPrice_R?pre_id=' +
        pid +
        '&amt=' +
        amt +
        '&isOnline=' +
        isonline,
    )
  }
  updateALLPROAMT_R(myproduct) {
    return this.http.post(this.ecom_urllive + 'OrderDashboard/updateAllPreDefPrice_R', myproduct)
  }
  updateProdName(pid, name) {
    return this.http.get(
      this.ecom_urllive + 'OrderDashboard/updateProdName?pid=' + pid + '&name=' + name,
    )
  }
  updateProdstatus(spgId, status) {
    return this.http.get(
      this.ecom_urllive +
        'OrderDashboard/updateProdISOnline?spgid=' +
        spgId +
        '&isonline=' +
        status,
    )
  }
  Addpredef(pid, b_qty, f_qty, title, price) {
    return this.http.get(
      this.ecom_urllive +
        'OrderDashboard/createNewPreQty?pid=' +
        pid +
        '&b_qty=' +
        b_qty +
        '&f_qty=' +
        f_qty +
        '&title=' +
        title +
        '&price=' +
        price,
    )
  }

  //--- GOPI_ END

  GetAttRpt(CompanyId, ContactId, Date) {
    return this.http.get(
      this.base_url3 +
        'Login/GetAttendanceRpt?companyId=' +
        CompanyId +
        '&contid=' +
        ContactId +
        '&date=' +
        Date,
    )
  }
  //ATT 2.0
  GetAttendanceValues(conid, frmdate, Todate, frmwhrs, Towhrs, frmbhrs, Tobhrs, strid) {
    return this.http.get(
      this.base_url3 +
        'Login/GetAttendanceValues?conid=' +
        conid +
        '&frmdate=' +
        frmdate +
        '&Todate=' +
        Todate +
        '&frmwhrs=' +
        frmwhrs +
        '&Towhrs=' +
        Towhrs +
        '&frmbhrs=' +
        frmbhrs +
        '&Tobhrs=' +
        Tobhrs +
        '&strid=' +
        strid,
    )
  }

  //Attedance
  getemployee(CompanyId) {
    return this.http.get(this.base_url3 + 'Login/GetEmployee?companyId=' + CompanyId)
  }
  // Add Spl Prod
  // saveProduct(image, name, price, catid) {
  //   const body = new FormData()
  //   body.append('image', image)
  //   return this.http.post(
  //     this.ecom_urllive +
  //       'Ecommerce/AddTemp_prod?name=' +
  //       name +
  //       '&price=' +
  //       price +
  //       '&catid=' +
  //       catid,
  //     body,
  //   )
  // }

  //TEMP//
  // tempstores(CompanyId) {
  //   return this.http.get(this.base_url + 'Store/Get?companyId=' + CompanyId)
  // }

  // tempprods(CatId: number) {
  //   return this.http.get(this.base_url + 'Store/GetProds?CatId=' + CatId)
  // }

  temp_internal(tostoreid, fromstoreid, payload) {
    return this.http.post(
      this.base_url + 'Store/PlaceIntOrder?tostoreid=' + tostoreid + '&fromstoreid=' + fromstoreid,
      payload,
    )
  }

  temp_listorders(storeid: number, storeid_new: number) {
    return this.http.get(
      this.base_url + 'Store/GetInternalOrders?storeid=' + storeid + '&storeid_new=' + storeid_new,
    )
  }

  temp_updateDispatch(DisQuantity: number, UpdateId: number) {
    return this.http.get(
      this.base_url +
        'Store/Update_DisQuantity?DisQuantity=' +
        DisQuantity +
        '&UpdateId=' +
        UpdateId,
    )
  }

  temp_updateReceive(UpdateId: number, status: number, RecQuantity: number) {
    return this.http.get(
      this.base_url +
        'Store/Update_RecQuantity?UpdateId=' +
        UpdateId +
        '&status=' +
        status +
        '&RecQuantity=' +
        RecQuantity,
    )
  }

  //TEMP//
  tempstores(CompanyId) {
    return this.http.get(this.base_url + 'Store/Get?companyId=' + CompanyId)
  }

  tempprods(CatId: number) {
    return this.http.get(this.base_url + 'Store/GetProds?CatId=' + CatId)
  }

  //image

  savetemppro(name, price) {
    return this.http.get(
      this.ecom_urllive + 'Ecommerce/AddTemp_prod?name=' + name + '&price=' + price,
    )
  }
  savetemppro2() {
    return this.http.get(this.ecom_urllive + 'Ecommerce/AddTemp_img')
  }

  saveProduct(image, name, price, catid) {
    const body = new FormData()
    body.append('image', image)
    return this.http.post(
      this.ecom_urllive +
        'Ecommerce/AddTemp_prod?name=' +
        name +
        '&price=' +
        price +
        '&catid=' +
        catid,
      body,
    )
  }

  getCompanies() {
    return this.http.get(this.ecom_urllive + 'Ecommerce/getUPColist')
  }

  getCat(comp_id) {
    return this.http.get(this.ecom_urllive + 'Ecommerce/getUPCalist?CompanyId=' + 100)
  }

  saveProductUP(name, desc, price, catid, taxid, protid, comid, image) {
    const body = new FormData()
    body.append('image', image)
    return this.http.post(
      this.ecom_urllive +
        'Ecommerce/AddUPTemp_prod?name=' +
        name +
        '&desc=' +
        desc +
        '&price=' +
        price +
        '&cateId=' +
        catid +
        '&taxgId=' +
        taxid +
        '&prodtyId=' +
        protid +
        '&CompId=' +
        comid,
      body,
    )
  }

  getUPPCat() {
    return this.http.get(this.ecom_urllive + 'Ecommerce/GETPCatList')
  }

  saveParentcat4UP(name) {
    return this.http.get(this.ecom_urllive + 'Ecommerce/AddUPTemp_prodCate?name=' + name)
  }

  saveChildcat4UP(name, pid) {
    return this.http.get(this.ecom_urllive + 'Ecommerce/AddUPCH_Cate?name=' + name + '&pid=' + pid)
  }

  ListUP_Pro(cid) {
    return this.http.get(this.ecom_urllive + 'Ecommerce/listUPprodTB?companyId=' + cid)
  }

  UpdateUpProducts(body) {
    return this.http.put(this.ecom_urllive + 'Ecommerce/SaveEditUP_products', body)
  }
  // END

  registration(payload) {
    return this.http.post(this.base_url1 + 'Login/Register', payload)
  }

  login(payload) {
    return this.http.post(this.base_url + 'Login/LoginCheck', payload)
  }
  addCustomers(payload) {
    return this.http.post(this.base_url1 + 'Customer/addData', payload)
  }

  getusers(pin, companyid) {
    return this.http.get(this.base_url1 + `Login/getstoreusers?pin=${pin}&companyId=${companyid}`)
  }
  getstoredata(companyid, storeid, pricetype) {
    return this.http.get(
      this.base_url1 +
        `Login/getStoreData?CompanyId=${companyid}&storeid=${storeid}&pricetype=${pricetype}`,
    )
  }

  checkifserver(ip) {
    return this.http.get(`http://${ip}:8081/checkifserver`).pipe(
      catchError(err => {
        console.log('error caught in service')
        console.error(err)

        //Handle the error here

        return throwError(err) //Rethrow it back to component
      }),
    )
  }
  getclientlist(ip) {
    return this.http.get(`http://${ip}:8081/getclients`)
  }
  joinserver(ip) {
    return this.http.get(`http://${ip}:8081/join`)
  }
  getKotgroups() {
    return this.http.get(this.base_url1 + 'Product/getKotGroup')
  }
  getvariantgroups_l(CompanyId) {
    return this.http.get(this.base_url1 + 'Product/getvariantgroups?CompanyId=' + CompanyId)
  }
  addVariants_l(variant) {
    return this.http.post(this.base_url1 + 'Product/addvariant', variant)
  }

  addVariantGroups_l(variantgroup) {
    return this.http.post(this.base_url1 + 'Product/addvariantgroup', variantgroup)
  }
  updateVariantGroups_l(optiongroup) {
    return this.http.post(this.base_url1 + 'Product/updatevariantgroup', optiongroup)
  }
  updateVariant_l(option) {
    return this.http.post(this.base_url1 + 'Product/updatevariant', option)
  }

  getBarcodeProduct(CompanyId, storeId) {
    return this.http.get(
      this.base_url1 + 'Product/getbarcodeproduct?CompanyId=' + CompanyId + '&storeId=' + storeId,
    )
  }

  getStockProduct(CompanyId, StoreId) {
    return this.http.get(this.base_url1 + 'Product/getStockProduct?CompanyId=1&StoreId=26')
  }
  getstockEntry(stockBatches) {
    return this.http.post(this.base_url1 + 'Product/stockEntry', stockBatches)
  }
  getMasterProduct(companyid) {
    return this.http.get(this.base_url1 + 'Product/getmasterproducts?CompanyId=' + companyid)
  }
  getproductbyid(id) {
    return this.http.get(this.base_url1 + 'Product/getproductbyid?ProductId=' + id)
  }

  getcategories(companyid, type) {
    return this.http.get(
      this.base_url1 + 'Category/getcategories?companyid=' + companyid + '&type=' + type,
    )
  }
  addcategories(category) {
    return this.http.post(this.base_url1 + 'Category/addcategory', category)
  }

  updatecategory(category) {
    return this.http.post(this.base_url1 + 'Category/updatecategory', category)
  }
  getcategorybyid(id) {
    return this.http.get(this.base_url1 + 'Category/getcategorybyid?CategoryId=' + id)
  }
  getcategoryvariants(id) {
    return this.http.get(this.base_url1 + 'Product/getcategoryvariants?categoryid=' + id)
  }
  getvendors(compid) {
    return this.http.get(this.base_url1 + 'Vendor/getVendorList?CompanyId=' + compid)
  }
  addvendors(vendor) {
    return this.http.post(this.base_url1 + 'Vendor/addvendors', vendor)
  }
  updatevendors(vendor) {
    return this.http.post(this.base_url1 + 'Vendor/updatevendors', vendor)
  }
  getVendorListbyid(id) {
    return this.http.get(this.base_url1 + 'Vendor/getVendorListbyid?vendorid=' + id)
  }

  getProductType() {
    return this.http.get(this.base_url1 + 'Product/getProductType')
  }

  getUnits() {
    return this.http.get(this.base_url1 + 'Product/getUnits')
  }

  getvariants(CompanyId) {
    return this.http.get(this.base_url1 + 'Product/getvariants?CompanyId=' + CompanyId)
  }

  getvariantgroups(CompanyId) {
    return this.http.get(this.base_url1 + 'Product/getvariantgroups?CompanyId=' + CompanyId)
  }

  getstores(compid) {
    return this.http.get(this.base_url1 + 'Internal/getStoreList?CompanyId=' + compid)
  }
  PaymentTypesList(compid) {
    return this.http.get(this.base_url1 + 'CreditTrx/getPaymentTypesList?CompanyId=' + compid)
  }
  getstoredata1(compid) {
    return this.http.get(this.base_url1 + 'Internal/getStoreData?CompanyId=' + compid)
  }

  getCreditdata(ord) {
    return this.http.post(this.base_url1 + 'CreditTrx/GetCreditTrx', ord)
  }
  getTransdata(ord) {
    return this.http.post(this.base_url1 + 'CreditTrx/EditCreditTrx', ord)
  }
  getvendorbill(id, storeId, companyId, vendorId, billtype) {
    return this.http.get(
      this.base_url1 +
        'PurchaseTrx/PayBillFor?id=' +
        id +
        '&storeId=' +
        storeId +
        '&companyId=' +
        companyId +
        '&vendorId=' +
        vendorId +
        '&billType=' +
        billtype,
    )
  }
  getRecreditData(Id, ContactId) {
    return this.http.get(
      this.base_url1 + 'CreditTrx/RepayCreditFor?compId=' + Id + '&id=' + ContactId,
    )
  }
  EditBillPay(id, billtype, companyId) {
    return this.http.get(
      this.base_url1 +
        'PurchaseTrx/EditBillPay?id=' +
        id +
        '&billType=' +
        billtype +
        '&compId=' +
        companyId,
    )
  }
  getorder(ordId) {
    return this.http.post(this.base_url1 + 'Internal/getOrderList', ordId)
  }
  getorderPrd(compid, ordId) {
    return this.http.get(
      this.base_url1 + 'Internal/GetOrderedItems?CompanyId=' + compid + '&orderId=' + ordId,
    )
  }
  savestock(order) {
    return this.http.post(this.base_url1 + 'Internal/saveIntOrd', order)
  }
  editInternalord(id) {
    return this.http.get(this.base_url1 + 'Internal/EditInternalOrd?id=' + id)
  }
  corsTest(ord) {
    return this.http.post(this.base_url1 + 'Internal/corsTest', ord)
  }
  dispatch(ord) {
    return this.http.post(this.base_url1 + 'Internal/OrdDispatch', ord)
  }
  getStockContainer(CompanyId, StoreId) {
    return this.http.get(
      this.base_url1 + 'Internal/getStockContainer?CompanyId=' + CompanyId + '&storeId=' + StoreId,
    )
  }
  Update(ord) {
    return this.http.post(this.base_url1 + 'Internal/OrdUpdate', ord)
  }
  deleteOrdItem(CompanyId, Id) {
    return this.http.get(
      this.base_url1 + 'Internal/Delete?CompanyId=' + CompanyId + '&ItemId=' + Id,
    )
  }
  deletedata(Id) {
    return this.http.post(this.base_url1 + 'Internal/Delete', Id)
  }

  deleteItem(Id) {
    return this.http.post(this.base_url1 + 'Internal/DeleteOrder', Id)
  }
  billdetail(Id, CompanyId) {
    return this.http.get(
      this.base_url1 + 'PurchaseTrx/BillPayDetails?id=' + Id + '&compId=' + CompanyId,
    )
  }
  deleteCredit(data) {
    return this.http.post(this.base_url1 + 'CreditTrx/DeleteCreditTrx', data)
  }
  billpayfor(data) {
    return this.http.post(this.base_url1 + 'PurchaseTrx/BillPayFor', data)
  }
  dispatchItem(data) {
    return this.http.post(this.base_url1 + 'Internal/GetOrderItems', data)
  }
  getDispatchList(data) {
    return this.http.post(this.base_url1 + 'Internal/ReceiveDispatch', data)
  }
  receive(data) {
    return this.http.post(this.base_url1 + 'Internal/Receive', data)
  }
  Ordrecve(data) {
    return this.http.post(this.base_url1 + 'Internal/OrdReceive', data)
  }

  getReceivePrd(data) {
    return this.http.post(this.base_url1 + 'Internal/EditReceive', data)
  }
  UpdateReceive(ord) {
    return this.http.post(this.base_url1 + 'Internal/UpdateReceive', ord)
  }
  UpdateReceiveOrd(ord) {
    return this.http.post(this.base_url1 + 'Internal/UpdateReceiveOrd', ord)
  }

  getOrdDisp(storeId, companyId) {
    return this.http.get(
      this.base_url1 + 'Internal/getDispatchList?storeId=' + storeId + '&companyId=' + companyId,
    )
  }
  Creditpay(paycred) {
    return this.http.post(this.base_url1 + 'CreditTrx/PayCredit', paycred)
  }

  purchasepay(paycred) {
    return this.http.post(this.base_url1 + 'PurchaseTrx/Updatebill', paycred)
  }
  billpay(paycred) {
    return this.http.post(this.base_url1 + 'PurchaseTrx/Submit', paycred)
  }
  UpdCredit(paycred) {
    return this.http.post(this.base_url1 + 'CreditTrx/UpdateCredit', paycred)
  }
  DeleteCreditpay(data) {
    return this.http.post(this.base_url1 + 'CreditTrx/DeleteOrder', data)
  }
  Deletebillpay(id, companyId) {
    return this.http.get(
      this.base_url1 + 'PurchaseTrx/DeleteBillPay?id=' + id + '&companyId=' + companyId,
    )
  }
  getCreditrepaydata(ord) {
    return this.http.post(this.base_url1 + 'CreditTrx/GetCreditRepayTrx', ord)
  }
  getpurchmaint(data) {
    return this.http.post(this.base_url1 + 'Purchase/GetPurchaseTrx', data)
  }
  getbillpay(data) {
    return this.http.post(this.base_url1 + 'PurchaseTrx/GetBillPay', data)
  }
  getbillpayvendor(data) {
    return this.http.post(this.base_url1 + 'PurchaseTrx/GetPendingBills', data)
  }
  getmaintbill(CompanyId) {
    return this.http.get(this.base_url1 + 'MaintBillTypes/GetMaintBillTypes?companyId=' + CompanyId)
  }
  getasset(CompanyId) {
    return this.http.get(this.base_url1 + 'Liability/GetLiability?companyId=' + CompanyId)
  }
  getassettype(CompanyId) {
    return this.http.get(this.base_url1 + 'LiabilityTypes/GetLiabilityTypes?companyId=' + CompanyId)
  }
  savemaintbill(maintBillType) {
    return this.http.post(this.base_url1 + 'MaintBillTypes/AddMaintBillType', maintBillType)
  }
  editmaintbill(Id) {
    return this.http.get(this.base_url1 + 'MaintBillTypes/GetMaintBillTypeById?id=' + Id)
  }
  updmaintbill(data) {
    return this.http.post(this.base_url1 + 'MaintBillTypes/UpdateMaintBillType', data)
  }
  DeActivatebill(Id) {
    return this.http.get(this.base_url1 + 'MaintBillTypes/Deactivate?id=' + Id)
  }
  saveassettype(assettype) {
    return this.http.post(this.base_url1 + 'LiabilityTypes/AddLiabilityTypes', assettype)
  }
  editassettype(Id) {
    return this.http.get(this.base_url1 + 'LiabilityTypes/GetLiabTypeById?id=' + Id)
  }
  updassettype(data) {
    return this.http.post(this.base_url1 + 'LiabilityTypes/UpdateLiabilityType', data)
  }
  DeActivateassettype(Id) {
    return this.http.get(this.base_url1 + 'LiabilityTypes/Deactivate?id=' + Id)
  }
  saveasset(assettype) {
    return this.http.post(this.base_url1 + 'Liability/AddLiability', assettype)
  }
  editasset(Id) {
    return this.http.get(this.base_url1 + 'Liability/GetLiabilityById?id=' + Id)
  }
  updasset(data) {
    return this.http.post(this.base_url1 + 'Liability/UpdateLiability', data)
  }
  DeActivateasset(Id) {
    return this.http.get(this.base_url1 + 'Liability/Deactivate?id=' + Id)
  }

  getlocation(CompanyId) {
    return this.http.get(this.base_url1 + 'Store/GetLocation?companyId=' + CompanyId)
  }

  DeActivatestore(Id) {
    return this.http.get(this.base_url1 + 'Store/Deactivate?id=' + Id)
  }
  savelocate(assettype) {
    return this.http.post(this.base_url1 + 'Store/AddLocation', assettype)
  }
  editlocate(Id) {
    return this.http.get(this.base_url1 + 'Store/GetStoreById?id=' + Id)
  }
  updlocate(data) {
    return this.http.post(this.base_url1 + 'Store/UpdateStore', data)
  }
  getcontactdata(CompanyId) {
    return this.http.get(this.base_url1 + 'Internal/getStoreData?CompanyId=' + CompanyId)
  }
  getaccount(CompanyId) {
    return this.http.get(this.base_url1 + 'BankAccount/GetBankacct?companyId=' + CompanyId)
  }
  DeActivateaccount(Id) {
    return this.http.get(this.base_url1 + 'BankAccount/Deactivate?id=' + Id)
  }
  saveaccount(account) {
    return this.http.post(this.base_url1 + 'BankAccount/AddBankaccount', account)
  }
  editaccount(Id) {
    return this.http.get(this.base_url1 + 'BankAccount/GetAccountById?id=' + Id)
  }
  updaccount(data) {
    return this.http.post(this.base_url1 + 'BankAccount/UpdateBankAccount', data)
  }
  getbankaccount(data) {
    return this.http.post(this.base_url1 + 'BankAccount/GetBankaccount', data)
  }
  savepurchase(order) {
    return this.http.post(this.base_url1 + 'Purchase/Purchase', order)
  }
  getaccountType(Id) {
    return this.http.get(this.base_url1 + 'BankAccount/GetacctType?companyId=' + Id)
  }
  getrepaydata(CompanyId, Id) {
    return this.http.get(this.base_url1 + 'CreditTrx/Credits?compId=' + CompanyId + '&id=' + Id)
  }
  getbillnpays(Id, vendorId) {
    return this.http.get(
      this.base_url1 + 'PurchaseTrx/GetBillsPayVendor?storeId=' + Id + '&vendorId=' + vendorId,
    )
  }
  Submitpay(data) {
    return this.http.post(this.base_url1 + 'CreditTrx/Submit', data)
  }
  prdactive(Id, active) {
    return this.http.get(this.base_url1 + 'Product/UpdateAct?Id=' + Id + '&active=' + active)
  }
  getProduct(id, compId, fromdate, todate) {
    return this.http.get(
      this.base_url1 +
        'Product/GetById?id=' +
        id +
        '&compId=' +
        compId +
        '&fromdate=' +
        fromdate +
        '&todate=' +
        todate,
    )
  }
  getbatchEntry(batches, userid) {
    return this.http.post(this.base_url1 + 'Product/batchEntry?userid=' + userid, batches)
  }
  getvariant_l(CompanyId) {
    return this.http.get(this.base_url1 + 'Product/getvariants?CompanyId=' + CompanyId)
  }

  updatepreference(preferences) {
    return this.http.post(this.base_url1 + 'Preference/updatepricetype', preferences)
  }
  getvendororders(
    companyid,
    storeid,
    vendorid,
    orderid,
    billid,
    productid,
    orderstatus,
    receivestatus,
    numofrecods,
    cancelstatus,
  ) {
    return this.http.get(
      this.base_url1 +
        `Purchase/getVendorOrders?companyid=${companyid}&storeid=${storeid}&vendorid=${vendorid}&orderid=${orderid}&billid=${billid}&productid=${productid}&orderstatus=${orderstatus}&receivestatus=${receivestatus}&numofrecods=${numofrecods}&cancelstatus=${cancelstatus}`,
    )
  }

  getvendororderproduct(orderId) {
    return this.http.get(this.base_url1 + `Purchase/getVendorOrderProduct?orderId=${orderId}`)
  }

  updatePurchase(order) {
    return this.http.post(this.base_url1 + 'Purchase/updatePurchase', order)
  }

  updatereceivedItem(orderid, userid, order) {
    return this.http.post(
      this.base_url1 + `Purchase/updatereceiveItem?orderid=${orderid}&userid=${userid}`,
      order.Items,
    )
  }
  directreceivedpurchase(orderid, userid, order) {
    return this.http.post(
      this.base_url1 + `Purchase/directReceiveItem?orderid=${orderid}&userid=${userid}`,
      order,
    )
  }
  getreceivedorders(companyid, storeid) {
    return this.http.get(
      this.base_url1 + `Purchase/getReceivedOrders?companyid=${companyid}&storeid=${storeid}`,
    )
  }
  getvendorreceivedorders(orderId) {
    return this.http.get(this.base_url1 + `Purchase/getVendorReceivedOrder?orderId=${orderId}`)
  }
  updatereceivedPurchase(orderid, userid, order) {
    return this.http.post(
      this.base_url1 + `Purchase/UpdateReceiveOrder?orderid=${orderid}&userid=${userid}`,
      order,
    )
  }

  getOrders() {
    return this.http.get(this.base_url1 + 'Product/getorders')
  }

  GetReceipts(Storeid, fromdate, todate, invoice) {
    return this.http.get(
      this.base_url1 +
        'Receipt/Gettestdata?Storeid=' +
        Storeid +
        '&fromdate=' +
        fromdate +
        '&todate=' +
        todate +
        '&InvoiceNo=' +
        invoice,
    )
  }

  GetCustomer(CompanyId) {
    return this.http.get(this.base_url1 + 'Customer/GetIndex?CompanyId=' + CompanyId)
  }
  UpdateCustomer(data) {
    return this.http.get(this.base_url1 + 'Customer/Update', data)
  }
  Deletecustomer(Id) {
    return this.http.get(this.base_url1 + 'Customer/Delete?Id=' + Id)
  }
  getCustomerByPhone(Phone) {
    return this.http.get(this.base_url1 + 'Customer/GetCustomerByPhone?Phone=' + Phone)
  }
  Addcustomers() {
    return this.http.get(this.base_url1 + 'Customer/AddCustomer')
  }
  updateCustomer(payload) {
    return this.http.put(this.base_url1 + 'Customer/updateData', payload)
  }
  saveorder(payload) {
    return this.http.post(this.base_url1 + 'Sale/SaveOrder_Test', payload)
  }
  ordertransaction(transactionlist) {
    return this.http.post(this.base_url1 + `Receipt/ordertransaction`, transactionlist)
  }
  // Master 18-05-2023
  unlockscreen(Pin, CompanyId) {
    return this.http.get(this.base_url1 + 'Login/pinlogin?pin=' + Pin + '&companyid=' + CompanyId)
  }

  // Master
  // 05-01-2022
  GetTaxGrp(CompanyId) {
    return this.http.get(this.base_url1 + 'TaxGroup/GetTaxGrp?CompanyId=' + CompanyId)
  }
  // Master
  // 06-01-2022
  AddTaxGrp(taxgroup) {
    return this.http.post(this.base_url1 + 'TaxGroup/AddTaxGrp', taxgroup)
  }
  UpdateTaxGrp(taxgroup) {
    return this.http.post(this.base_url1 + 'TaxGroup/UpdateTaxGrp', taxgroup)
  }
  //Queen 29-01-2022
  // 06-06-2022
  getorderlist(storeId) {
    return this.http.get(
      this.base_url1 + 'Internal/getOrderList?storeId=' + storeId + '&numRecords=50',
    )
  }

  // Queen 31-01-2022
  getstoreIdInternal(CompanyId, Id) {
    return this.http.get(
      this.base_url1 + 'Internal/GetstorebyId?CompanyId=' + CompanyId + '&Id=' + Id,
    )
  }

  // Master
  // 28-01-2022

  Categoryupdate(Id, active, CompanyId) {
    return this.http.get(
      this.base_url1 +
        'Category/UpdatecategoryAct?Id=' +
        Id +
        '&active=' +
        active +
        '&CompanyId=' +
        CompanyId,
    )
  }
  catactive(companyid) {
    return this.http.get(this.base_url1 + 'Category/Index?companyid=' + companyid)
  }
  gettransaction(OrderId) {
    return this.http.get(this.base_url1 + 'Receipt/getByOrderId?OrderId=' + OrderId)
  }

  // Master
  // 18-02-2022
  GetReceiveList(CompanyId, StoreId, OrdStsId) {
    return this.http.get(
      this.base_url1 +
        'Internal/GetReceiveList?companyid=' +
        CompanyId +
        '&storeid=' +
        StoreId +
        '&ordStsId=' +
        OrdStsId,
    )
  }

  // 06-06-2022
  getOrderIdinternal(OrdId) {
    return this.http.get(this.base_url1 + 'Internal/internalordbyid?orderid=' + OrdId)
  }
  GetDispatch(companyId, storeId, OrdStsId) {
    return this.http.get(
      this.base_url1 +
        'Internal/GetDispatch?companyId=' +
        companyId +
        '&StoreId=' +
        storeId +
        '&OrdStsId=' +
        OrdStsId,
    )
  }
  getreceivebyid(OrdId) {
    return this.http.get(this.base_url1 + 'Internal/receivebyid?orderid=' + OrdId)
  }

  // master
  addCustomer(payload) {
    return this.http.post(this.base_url1 + 'Customer/addData', payload)
  }
  //Queen14-09-2022
  getCredit(compid, billStatus) {
    return this.http.get(
      this.base_url1 + 'CreditTrx/TestCreditData?CompanyId=' + compid + '&billStatus=' + billStatus,
    )
  }
  //Queen 23-09-2022
  getpurchase(compid) {
    return this.http.get(this.base_url1 + 'PurchaseTrx/TestPurchaseData?CompanyId=' + compid)
  }
  // Queen 22-10-2022
  savecredit(crd) {
    return this.http.post(this.base_url1 + 'CreditTrx/savecredit', crd)
  }
  //Queen 31-10-2022
  getcontact(CompanyId, ContactTypeId) {
    return this.http.get(
      this.base_url1 +
        'CreditTrx/GetContact?CompanyId=' +
        CompanyId +
        '&ContactTypeId=' +
        ContactTypeId,
    )
  }
  getinputdata(CompanyId) {
    return this.http.get(this.base_url1 + 'CreditTrx/getTabledata?CompanyId=' + CompanyId)
  }
  getbillstatus(CompanyId) {
    return this.http.get(this.base_url1 + 'CreditTrx/Getbillstatus?CompanyId=' + CompanyId)
  }

  saverepay(crejson) {
    return this.http.post(this.base_url1 + 'CreditTrx/repay', crejson)
  }
  getdatabyid(id) {
    return this.http.get(this.base_url1 + 'CreditTrx/getbyId?id=' + id)
  }
  getrepaycondatabyid(id) {
    return this.http.get(this.base_url1 + 'CreditTrx/getclickbyId?id=' + id)
  }
  getrepayIndex(CompanyId) {
    return this.http.get(this.base_url1 + 'CreditTrx/GetRepayIndex?CompanyId=' + CompanyId)
  }
  geteditcontbyid(id) {
    return this.http.get(this.base_url1 + 'CreditTrx/getbyIdforedit?transid=' + id)
  }
  saveeditdata(editjson) {
    return this.http.post(this.base_url1 + 'CreditTrx/EditRepay', editjson)
  }
  Deleterepaydata(transid, billid, amt) {
    return this.http.get(
      this.base_url1 +
        'CreditTrx/DeleteRepaydata?transid=' +
        transid +
        '&billid=' +
        billid +
        '&amount=' +
        amt,
    )
  }
  getcreditdetail(transid) {
    return this.http.get(this.base_url1 + 'CreditTrx/creditdetail?id=' + transid)
  }

  // Master Team 2K23[R & K] Update

  //Kowsi - Internal
  getintprod(CompanyId, StoreId, testid) {
    return this.http.get(
      this.base_url1 +
        'Internal/GetInternalproduct?CompanyId=' +
        CompanyId +
        '&StoreId=' +
        StoreId +
        '&testid=' +
        testid,
    )
  }
  getstoreslist(companyid, storeid) {
    return this.http.get(
      this.base_url1 + 'Internal/getstoreslist?companyid=' + companyid + '&storeid=' + storeid,
    )
  }

  //Queen30-12-2022
  // getdispatchstore(ordid) {
  //   return this.http.get(this.base_url1 + 'Internal/getdispatchbyid?ordid=' + ordid)
  // }
  getbatchdata(ordid, prodid) {
    return this.http.get(
      this.base_url1 + 'Internal/getbatchquantity?ordid=' + ordid + '&prodid=' + prodid,
    )
  }

  //Queen 23-01-2023
  getpurproducts(companyid, venid) {
    return this.http.get(
      this.base_url1 + 'Purchase/GetProducts?companyId=' + companyid + '&vendorId=' + venid,
    )
  }

  //Queen 24-01-2023
  getinterproduct(CompanyId) {
    return this.http.get(this.base_url1 + 'Internal/getproducts?companyId=' + CompanyId)
  }
  getliabilitybyId(CompanyId, liatypeid) {
    return this.http.get(
      this.base_url1 +
        'MaintBillTypes/getliabilityBytypeId?companyId=' +
        CompanyId +
        '&liatypeid=' +
        liatypeid,
    )
  }
  getvendorsmaint(CompanyId) {
    return this.http.get(this.base_url1 + 'MaintBillTypes/getMaintVendors?companyId=' + CompanyId)
  }
  getcontType(CompanyId) {
    return this.http.get(this.base_url1 + 'MaintBillTypes/getContactType?companyId=' + CompanyId)
  }
  Savemaintenbill(maintjson) {
    return this.http.post(this.base_url1 + 'MaintBillTypes/SaveMaintBill', maintjson)
  }
  getmaintbillIndex(CompanyId, StoreId) {
    return this.http.get(
      this.base_url1 +
        'MaintBillTypes/GetMaintBillIndex?companyId=' +
        CompanyId +
        '&storeId=' +
        StoreId,
    )
  }

  getmaintbillIndexRec(CompanyId, StoreId) {
    return this.http.get(
      this.base_url1 +
        'MaintBillTypes/GetMaintBillIndexRec?companyId=' +
        CompanyId +
        '&storeId=' +
        StoreId,
    )
  }

  getfrequency(CompanyId) {
    return this.http.get(this.base_url1 + 'MaintBillTypes/getFrequencyType?companyId=' + CompanyId)
  }

  //19-01-2023
  getvendorserach(CompanyId) {
    return this.http.get(this.base_url1 + 'PurchaseTrx/getvendors?CompanyId=' + CompanyId)
  }
  //20-01-2023
  getbilldatabyid(CompanyId, vendorid, storeid) {
    return this.http.get(
      this.base_url1 +
        'PurchaseTrx/getbillData?companyid=' +
        CompanyId +
        '&vendorid=' +
        vendorid +
        '&storeid=' +
        storeid,
    )
  }

  getcompanydata(companyId) {
    return this.http.get(this.base_url1 + 'Customer/Index?companyId=' + companyId)
  }

  saveCompany(formdata) {
    let body = this.toFormData(formdata)
    return this.http.post(this.base_url1 + `Customer/SaveData`, body)
  }

  getstore(CompanyId) {
    return this.http.get(this.base_url1 + 'Store/Get?CompanyId=' + CompanyId)
  }

  addstore(payload) {
    return this.http.post(this.base_url1 + 'Store/AddStore', payload)
  }

  updatestore(body) {
    return this.http.post(this.base_url1 + `Store/UpdateData`, body)
  }
  role(companyId) {
    return this.http.get(this.base_url1 + 'Users/GetRole?companyId=' + companyId)
  }
  getuser(companyId) {
    return this.http.get(this.base_url1 + 'Users/GetUsers?companyId=' + companyId)
  }

  saveUser(playload) {
    return this.http.post(this.base_url1 + 'Users/Add', playload)
  }

  outletActive(id, active) {
    return this.http.get(this.base_url1 + 'Store/UpdateAct?id=' + id + '&active=' + active)
  }

  DeleteUser(Id) {
    return this.http.get(this.base_url1 + 'Users/Deleteuser?Id=' + Id)
  }

  // ImportProd
  importProduct(formdata, Id) {
    let body = this.toFormData(formdata)
    return this.http.post(this.base_url1 + 'Product/ImportProduct?companyId=' + Id, body, {
      headers: new HttpHeaders({
        // "APIKey": "5567GGH67225HYVGG",
        // "Content-Type": "application/json"
      }),
    })
  }
  importOG(payload, Id) {
    return this.http.post(this.base_url1 + 'OptionGroup/bulkogimport?companyid=' + Id, payload)
  }

  getkotgroup(CompanyId) {
    return this.http.get(this.base_url1 + 'KotGroup/GetKot?CompanyId=' + CompanyId)
  }

  AddKotGroup(kotgrp) {
    return this.http.post(this.base_url1 + 'KotGroup/addKotGroup', kotgrp)
  }

  UpdatekotGrp(kotgrp) {
    return this.http.post(this.base_url1 + 'KotGroup/UpdateKotGroup', kotgrp)
  }

  getkotgrpbyId(id) {
    return this.http.get(this.base_url1 + 'KotGroup/getkotgroupbyid?id=' + id)
  }
  DeleteKotgrp(id) {
    return this.http.get(this.base_url1 + 'KotGroup/Delete?id=' + id)
  }
  Getadditional(CompanyId) {
    return this.http.get(this.base_url1 + 'TaxGroup/GetAddtional?CompanyId=' + CompanyId)
  }

  Addadditional(additional) {
    return this.http.post(this.base_url1 + 'TaxGroup/Addadditional', additional)
  }

  Updateadditional(additional) {
    return this.http.post(this.base_url1 + 'TaxGroup/Updateadditional', additional)
  }

  UpdateTable(formdata) {
    let body = this.toFormData(formdata)
    return this.http.post(this.base_url1 + 'Sale/Update', body)
  }
  addtable(formdata) {
    let body = this.toFormData(formdata)
    return this.http.post(this.base_url1 + 'Sale/CreateArea', body)
  }

  deleteArea(Id) {
    return this.http.get(this.base_url1 + 'Sale/Delete?Id=' + Id)
  }
  getdining(CompanyId) {
    return this.http.get(this.base_url1 + 'Sale/Get?CompanyId=' + CompanyId)
  }
  EditTable(Id) {
    return this.http.get(this.base_url1 + 'Sale/GetById?Id=' + Id)
  }
  getOption(CompanyId) {
    return this.http.get(this.base_url1 + 'OptionGroup/Get?CompanyId=' + CompanyId)
  }

  saveoption(data) {
    return this.http.post(this.base_url1 + 'OptionGroup/CreateOption', data)
  }

  Updateoption(data) {
    return this.http.post(this.base_url1 + 'OptionGroup/Update', data)
  }

  optionsactive(Id, active) {
    return this.http.get(this.base_url1 + 'OptionGroup/UpdateAct?Id=' + Id + '&active=' + active)
  }

  EditOption(Id) {
    return this.http.get(this.base_url1 + 'OptionGroup/GetById?Id=' + Id)
  }

  addproduct_l(product) {
    return this.http.post(this.base_url1 + 'Product/addData', product)
  }
  updateproduct_l(product) {
    return this.http.post(this.base_url1 + 'Product/updateProduct', product)
  }

  getPaymentType(StoreId) {
    return this.http.get(this.base_url1 + 'PaymentType/getstorepaymenttype?storeid=' + StoreId)
  }

  AddPaymentType(data) {
    return this.http.post(this.base_url1 + 'PaymentType/AddPaymentType', data)
  }

  UpdatePaymentType(data) {
    return this.http.post(this.base_url1 + 'PaymentType/UpdatePaymentType', data)
  }

  DeletePaymentType(Id) {
    return this.http.get(this.base_url1 + 'PaymentType/DeletePaymentType?Id=' + Id)
  }
  getorderbyinvoice(invoice, companyId) {
    return this.http.get(
      this.base_url1 + 'Sale/GetOrderByinvoice?invoiceno=' + invoice + '&companyId=' + companyId,
    )
  }
  cancelorder(orderid, payload) {
    return this.http.post(this.base_url1 + `Sale/cancellorder?orderid=${orderid}`, payload)
  }

  getcat(CompanyId) {
    var formURL = this.base_url1
    var formURL = this.base_url1 + 'Category/Index?CompanyId=' + CompanyId
    return this.http.get(formURL)
  }

  getOpgMappedProducts(CompanyId, OptionGroupId) {
    return this.http.get(
      this.base_url1 +
        'OptionGroup/getMapedProducts?CompanyId=' +
        CompanyId +
        '&OptionGroupId=' +
        OptionGroupId,
    )
  }

  pogBulkSave(opgid, companyid, productids) {
    return this.http.post(
      this.base_url1 + 'OptionGroup/BulkSave?OptionGroupId=' + opgid + '&CompanyId=' + companyid,
      productids,
    )
  }

  getTaxGrpMappedProducts(CompanyId, TaxGroupId) {
    return this.http.get(
      this.base_url1 +
        'TaxGroup/getMapedProducts?CompanyId=' +
        CompanyId +
        '&TaxGroupId=' +
        TaxGroupId,
    )
  }

  taxgrpBulkSave(taxgrpid, companyid, productids) {
    return this.http.post(
      this.base_url1 + 'TaxGroup/BulkSave?TaxGroupId=' + taxgrpid + '&CompanyId=' + companyid,
      productids,
    )
  }

  orderwise(fromdate, todate, Storeid, Companyid) {
    return this.http.get(
      this.base_url1 +
        'Daywise/Ordwise?Storeid=' +
        Storeid +
        '&fromdate=' +
        fromdate +
        '&todate=' +
        todate +
        '&Companyid=' +
        Companyid,
    )
  }

  getordersById(CompanyId, OrdId) {
    return this.http.get(
      this.base_url1 + 'Internal/GetOrdersById?companyId=' + CompanyId + '&OrdId=' + OrdId,
    )
  }
  updateinternal(order) {
    return this.http.post(this.base_url1 + 'Internal/UpdateIntOrd', order)
  }
  IntOrdDispatch(order) {
    return this.http.post(this.base_url1 + 'Internal/SaveIntDispatch', order)
  }
  IntOrdReceive(id) {
    return this.http.get(this.base_url1 + 'Internal/SaveIntReceive?ordId=' + id)
  }

  // UrbanPiper
  //HYPERTECH
  urbandata(companyId) {
    let headers = new Headers({ 'Content-Type': 'application/json' })
    //let options = new RequestOptions({ headers: headers });
    var formURL = this.base_url4
    var formURL = this.base_url4 + 'UrbanPiperStores/GetIndex?companyId=' + companyId
    return this.http.get(formURL)
  }

  Getitem(storeId, CompanyId, brandId) {
    // console.log(storeId);
    return this.http.get(
      this.base_url4 +
        'UrbanPiper/GetPrd?Id=' +
        storeId +
        '&compId=' +
        CompanyId +
        '&brandId=' +
        brandId,
    )
  }

  bulksync(catalogue, companyid) {
    let body = this.toFormData(catalogue)
    // var companyid = 3
    return this.http.post(
      this.base_url4 + 'UrbanPiper/BulkCatalogue?' + '&companyId=' + companyid,
      body,
    )
  }

  UpdateProductUPPrice(frormdata) {
    let body = this.toFormData(frormdata)
    return this.http.post(this.base_url4 + 'UrbanPiperStores/StoreProduct', body)
  }

  UpdateOptionUPPrice(formdata) {
    let body = this.toFormData(formdata)
    return this.http.post(this.base_url4 + 'UrbanPiperStores/StoreOption', body)
  }

  catalogue(catalogue, storeId, brandId) {
    // console.log(catalogue);
    let body = this.toFormData(catalogue)
    var companyid = 3
    return this.http.post(
      this.base_url4 +
        'UrbanPiper/Catalouge?storeId=' +
        storeId +
        '&BrandId=' +
        brandId +
        '&companyId=' +
        companyid,
      body,
    )
  }

  items(item) {
    // console.log(item)
    let body = this.toFormData(item)
    var companyid = 3
    return this.http.post(this.base_url4 + 'UrbanPiper/ItemAction?companyId=' + companyid, body)
  }

  storeitemaction(formdata, brandId) {
    let body = this.toFormData(formdata)
    return this.http.post(this.base_url4 + 'Stores/itemaction?BrandId=' + brandId, body)
  }

  storeAction(jstring) {
    var data =
      '{"stores":[{"city":"Chennai","name":"FBCakes2","min_pickup_time":900,"min_delivery_time":1800,"contact_phone":"98234566222","notification_phones":["+919134345345","98234566778"],"ref_id":"1234567892584","min_order_value":200,"hide_from_ui":false,"address":"60, RGM Complex, OMR Road, Karapakkam","notification_emails":["b1@mail.com","b2@mail.com"],"zip_codes":["560033","560022"],"geo_longitude":80.2291568,"active":true,"geo_latitude":12.9137880,"ordering_enabled":true}],"translations":[{"language":"fr", "name":"c\'est un magasin"}]}'
    let body = this.toFormData(jstring)
    var companyid = 3
    return this.http.post(this.base_url4 + 'UrbanPiper/StoreAction?companyId=' + companyid, body)
  }

  getStores(compId) {
    var formURL = this.base_url4
    var formURL = this.base_url4 + 'Stores/Get?CompanyId=' + compId
    return this.http.get(formURL)
  }

  AddStore(jstring) {
    var data =
      '{"stores":[{"city":"Chennai","name":"FBCakes2","min_pickup_time":900,"min_delivery_time":1800,"contact_phone":"98234566222","notification_phones":["+919134345345","98234566778"],"ref_id":"1234567892584","min_order_value":200,"hide_from_ui":false,"address":"60, RGM Complex, OMR Road, Karapakkam","notification_emails":["b1@mail.com","b2@mail.com"],"zip_codes":["560033","560022"],"geo_longitude":80.2291568,"active":true,"geo_latitude":12.9137880,"ordering_enabled":true}],"translations":[{"language":"fr", "name":"c\'est un magasin"}]}'
    // console.log(jstring);
    var companyid = 3
    return this.http.get(
      this.base_url4 + 'UrbanPiper/AddStore?value=' + jstring + '&companyId=' + companyid,
    )
  }

  GetBrand(companyId) {
    return this.http.get(this.base_url4 + 'Brand/GetBrand?companyId=' + companyId)
  }

  fetchDenominationEntries(storeid, date, companyid, entrytypeid) {
    return this.http.get(
      this.base_url4 +
        `Denomination/getDenomEntry?storeid=${storeid}&date=${date}&companyid=${companyid}&entrytypeid=${entrytypeid}`,
    )
  }

  denominationTypes() {
    return this.http.get(this.base_url4 + `Denomination/GetDenominationTypes`)
  }

  getstores_emp(CompanyId) {
    return this.http.get(this.base_url3 + 'Store/GetStoresUnique?companyId=' + CompanyId)
  }

  Lggetstore(CompanyId, StoreId, UserId) {
    return this.http.get(
      this.base_url3 +
        'Store/GetStores?companyId=' +
        CompanyId +
        '&id=' +
        StoreId +
        '&UserId=' +
        UserId,
    )
  }
}
