import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  authorized: boolean

  constructor(private store: Store<any>, public router: Router) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.authorized = state.authorized
    })
  }
  roleConfig = {
    '7': { role: 'ADMIN', defaultPage: '/apps/products' },
    '1': { role: 'POSADMIN', defaultPage: '/apps/products' },
    '3': { role: 'POSCASHIER', defaultPage: '/apps/products' },
    '6': { role: 'ERPADMIN', defaultPage: '/apps/internaltransfer' },
    '0': { role: '', defaultPage: '/auth/pinscreen' },
    '-1': { role: '', defaultPage: '/auth/login' },
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // if (environment.authenticated) {
    //   // skip guard checking on demo environment serve/build, remove it in your app
    //   return true
    // }

    // if (this.authorized) {
    //   return true
    // }

    // this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } })
    return this.checkUserLogin(next) // false
  }

  checkUserLogin(route: ActivatedRouteSnapshot): boolean {
    // const logState = localStorage.getItem('logState')
    // if (logState !== 'logged_in') {
    //   this.router.navigate([this.roleConfig['-1'].defaultPage])
    //   return true
    // }
    // let roleid = JSON.parse(localStorage.getItem('user') || '{"roleId": 0}').roleId.toString()
    // let role = this.roleConfig[roleid].role
    // if (route.url[0].path == 'apps') return true
    // if (route.data.role && route.data.role.includes(role)) {
    //   console.log('Page Authorised..')
    //   return true
    // }
    // console.log('redirecting ...')
    // this.router.navigate([this.roleConfig[roleid].defaultPage])
    return true
  }
}
