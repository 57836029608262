<div class="topbar">
  <div class="mr-4">
    <!-- <cui-topbar-fav-pages></cui-topbar-fav-pages> -->
  </div>
  <div class="mr-auto">
    <!-- <cui-topbar-search></cui-topbar-search> -->
  </div>
  <div class="mr-4 d-none d-md-block">
    <!-- <cui-topbar-issues-history></cui-topbar-issues-history> -->
  </div>
  <div class="mb-0 mr-auto d-xl-block d-none">
    <!-- <cui-topbar-project-management></cui-topbar-project-management> -->
  </div>
  <div class="mr-4 d-none d-sm-block">
    <!-- <cui-topbar-language-switcher></cui-topbar-language-switcher> -->
  </div>
  <div *ngIf="is_erp_page" class="mr-4 d-none d-sm-block">
    <!-- <cui-topbar-actions></cui-topbar-actions> -->
    <select *ngIf="showStoreSelect" class="form-control" [(ngModel)]="storeid" (change)="setstoreid()">
      <option value="0" selected>All</option>
      <option value="{{store.id}}" *ngFor="let store of stores">{{store.name}}</option>
    </select>
  </div>
  <div>
    <cui-topbar-user-menu></cui-topbar-user-menu>
  </div>
</div>