<div class="footer">
  <div class="footerInner">
    <!-- <a href="https://sellpixels.com" target="_blank" rel="noopener noreferrer" class="logo">
      SELLPIXELS
      <span></span>
    </a> -->
    <a href="https://sellpixels.com" target="_blank" rel="noopener noreferrer" class="logo">
      Biz1Pos
      <span></span>
    </a>
    <br />
    <p class="mb-0">
      Copyright © 2017-2020 Mdtk Soft |
      <a href="https://www.mediatec.org/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
    </p>
  </div>
</div>
