<nz-sider nzCollapsible [nzWidth]="leftMenuWidth" [nzCollapsible]="!isMobileView"
  [(nzCollapsed)]="isMenuCollapsed && !isMobileView" (nzCollapsedChange)="onCollapse($event)" [ngClass]="{
  menu: true,
  white: menuColor === 'white',
  gray: menuColor === 'gray',
  dark: menuColor === 'dark',
  unfixed: isMenuUnfixed,
  shadow: isMenuShadow && !isMobileView
}">
  <div class="menuOuter" [ngStyle]="{
    width: isMenuCollapsed && !isMobileView ? '80px' : leftMenuWidth + 'px',
    height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)'
  }">
    <!-- <div class="logoContainer">
      <div class="logo">
        <img src="{{resources[role].logo}}" class="mr-2" alt="Clean UI" />
        <div class="name">{{ resources[role].title }}</div>
        <div *ngIf="logo === 'Biz1Pos'" class="descr">BizDom</div>
      </div>
    </div> -->
    <perfect-scrollbar>
      <ul nz-menu class="navigation" [nzInlineIndent]="15" [nzMode]="'inline'"
        [nzInlineCollapsed]="isMenuCollapsed && !isMobileView">
        <ng-container *ngTemplateOutlet="menuTpl; context: {$implicit: menuDataActivated, level: 0}"></ng-container>
        <ng-template #menuTpl let-menuDataActivated let-level="level">
          <ng-container *ngFor="let item of menuDataActivated">
            <ng-container *ngIf="!item.roles || item.roles.includes(role)">
              <ng-template #tooltipTpl>
                {{ item.title }}
              </ng-template>
              <!-- group title item -->
              <li nz-menu-group [nzTitle]="item.title" *ngIf="item.category"></li>
              <!-- disabled item -->
              <li nz-menu-item *ngIf="item.disabled" [nzSelected]="item.selected" [nzDisabled]="true" nz-tooltip
                nzTooltipPlacement="right" [nzTooltipTitle]="isMenuCollapsed && level === 0 ? tooltipTpl : ''">
                <span [ngStyle]="{'padding-left.px': isMenuCollapsed ? '0' : level * 15}">
                  <span class="title">{{item.title}}</span>
                  <span *ngIf="item.count" class="badge badge-success ml-2">{{ item.count }}</span>
                  <i *ngIf="item.icon" [ngClass]="[item.icon, 'icon']"></i>
                </span>
              </li>
              <!-- main item -->
              <li nz-menu-item *ngIf="!item.children && item.url" routerLink={{item.url}} [nzSelected]="item.selected"
                [nzDisabled]="item.disabled" nz-tooltip nzTooltipPlacement="right"
                [nzTooltipTitle]="isMenuCollapsed && level === 0 ? tooltipTpl : ''">
                <span [ngStyle]="{'padding-left.px': isMenuCollapsed ? '0' : level * 15}">
                  <span class="title">{{item.title}}</span>
                  <span *ngIf="item.count" class="badge badge-success ml-2">{{ item.count }}</span>
                  <i *ngIf="item.icon" [ngClass]="[item.icon, 'icon']"></i>
                </span>
              </li>
              <!-- submenu item with recursive template -->
              <li nz-submenu *ngIf="item.children" [nzOpen]="item.open && !isMenuCollapsed">
                <span title [ngStyle]="{'padding-left.px': isMenuCollapsed ? '0' : level * 15}">
                  <span class="title">{{item.title}}</span>
                  <span *ngIf="item.count" class="badge badge-success ml-2">{{ item.count }}</span>
                  <i *ngIf="item.icon" [ngClass]="[item.icon, 'icon']"></i>
                </span>
                <ul>
                  <ng-container *ngTemplateOutlet="menuTpl; context: {$implicit: item.children, level: level + 1}">
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ng-container>
        </ng-template>
      </ul>
      <!-- <div class="banner">
        <p>More components, more style, more themes, and premium support!</p>
        <a href="https://themeforest.net/item/clean-ui-react-admin-template/21938700" target="_blank"
          rel="noopener noreferrer" class="btn btn-sm btn-success btn-rounded px-3">Buy Bundle</a>
      </div> -->
    </perfect-scrollbar>
  </div>
</nz-sider>