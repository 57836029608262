export const getMenuData: any[] = [
  {
    category: true,
    title: 'Website',
  },
  // Attendance RPT 1.0
  // {
  //   title: 'Attendance Rpt',
  //   key: 'attendancerpt',
  //   icon: 'fa fa-building',
  //   url: '/apps/attendancerpt',
  // },
  // Attendance RPT 2.0
  {
    title: 'Attendance Index',
    key: 'atten-index',
    icon: 'fa fa-bookmark',
    url: '/apps/atten-index',
  },

  {
    title: 'Add Spl Product',
    key: 'attendancerpt',
    icon: 'fa fa-plus-circle',
    url: '/apps/addprod',
  },
  //urbanpiper

  {
    title: 'UrbanPiper',
    key: 'urbanpiper',
    icon: 'fa fa-address-book',
    url: '/apps/urbanpiper',
  },

  // denominations
  {
    title: 'Denomination',
    key: 'denominations',
    icon: 'fa fa-braille',
    url: '/apps/denominations',
  },

  {
    title: 'FBCakes Online',
    key: 'apps',
    icon: 'fe fe-shopping-bag',
    //roles: ['ADMIN', 'POSADMIN'],
    children: [
      {
        title: 'Order Dashboard',
        key: 'apps',
        url: '/apps/orderdashboard',
      },
      {
        title: 'Order Track',
        key: 'apps',
        url: '/apps/ordertrack',
      },
      {
        title: 'Order Report',
        key: 'apps',
        url: 'apps/fborderreport',
      },
      {
        title: 'Product Report',
        key: 'apps',
        url: 'apps/prodrpt',
      },
      {
        title: 'Customer List',
        key: 'apps',
        url: 'apps/customerlist',
      },
      {
        title: 'Order Enquiry',
        key: 'apps',
        url: 'apps/orderenquiry',
      },
    ],
  },
  // {
  //   title: 'Temp Internal Orders',
  //   key: 'tempapps',
  //   icon: 'fa fa-users',
  //   children: [
  //     {
  //       title: 'Temp_Internal Orders',
  //       key: 'appsSetting',
  //       icon: 'fa fa-building',
  //       url: '/apps/Temp_Internal',
  //     },
  //     {
  //       title: 'Dispatch / Receive',
  //       key: 'appsSetting',
  //       icon: 'fa fa-building',
  //       url: '/apps/Dispatch_Receive',
  //     },
  //   ],
  // },
  {
    title: 'Company Management',
    key: 'apps',
    icon: 'fa fa-users',
    roles: ['ADMIN', 'POSADMIN'],
    children: [
      {
        title: 'Temp_Internal',
        key: 'appsSetting',
        icon: 'fa fa-building',
        //roles: ['ADMIN', 'POSADMIN'],
        url: '/apps/Temp_Internal',
      },
      {
        title: 'Company',
        key: 'appsSetting',
        icon: 'fa fa-building',
        roles: ['ADMIN', 'POSADMIN'],
        url: '/apps/Company',
      },
      {
        title: 'Outlet',
        key: 'appsSetting',
        icon: 'fa fa-map-marker',
        roles: ['ADMIN', 'POSADMIN'],
        url: '/apps/Outlet',
      },
      {
        title: 'User',
        key: 'appsSetting',
        icon: 'fe fe-users',
        roles: ['ADMIN', 'POSADMIN'],
        url: '/apps/user',
      },
      {
        title: 'Cancel Order',
        key: 'appsSetting',
        icon: 'fa fa-times',
        roles: ['ADMIN', 'POSADMIN'],
        url: '/apps/cancel-order',
      },
    ],
  },
  {
    title: 'Menu Management',
    key: 'apps',
    icon: 'fe fe-menu',
    roles: ['ADMIN', 'POSADMIN', 'POSCASHIER', 'ERPADMIN'],
    children: [
      {
        title: 'Product',
        key: 'appsProfile',
        icon: 'fe fe-grid',
        roles: ['ADMIN', 'POSADMIN', 'POSCASHIER', 'ERPADMIN'],
        url: '/apps/products',
      },
      {
        title: 'Category',
        key: 'appsProfile',
        icon: 'fa fa-sitemap',
        roles: ['ADMIN', 'POSADMIN', 'POSCASHIER', 'ERPADMIN'],
        url: '/apps/category',
      },
      {
        title: 'TaxGroup',
        key: 'appsProfile',
        icon: 'fa fa-calculator',
        roles: ['ADMIN', 'POSADMIN', 'POSCASHIER', 'ERPADMIN'],
        url: '/apps/taxgroup',
      },
      {
        title: 'KotGroup',
        key: 'appsProfile',
        icon: 'fe fe-grid',
        roles: ['ADMIN', 'POSADMIN', 'POSCASHIER'],
        url: '/apps/Kot',
      },
      {
        title: 'Additional-Charge',
        key: 'appsSetting',
        icon: 'fa fa-money',
        roles: ['ADMIN', 'POSADMIN', 'POSCASHIER'],
        url: '/apps/Additional-Charge',
      },
      {
        title: 'Option-Group',
        key: 'appsSetting',
        icon: 'fa fa-calculator',
        roles: ['ADMIN', 'POSADMIN', 'POSCASHIER'],
        url: '/apps/Option-Group',
      },
      {
        title: 'Dine In',
        key: 'appsProductOptions',
        icon: 'fe fe-copy',
        roles: ['ADMIN', 'POSADMIN', 'POSCASHIER'],
        url: '/apps/dinein',
      },
      {
        title: 'PaymentType',
        key: 'appsPaymentType',
        icon: 'fe fe-check-square',
        roles: ['ADMIN', 'POSADMIN', 'POSCASHIER'],
        url: '/apps/paymentType',
      },
    ],
  },
  //Queen start 19-05-2023
  {
    title: 'ERP Management',
    key: 'apps',
    icon: 'fa fa-gears',
    roles: ['ADMIN', 'ERPADMIN'],
    children: [
      {
        title: 'Batch Entry',
        icon: 'fa fa-stack-overflow',
        roles: ['ADMIN', 'ERPADMIN'],
        key: 'appsBatchEntry',
        url: '/apps/batchentry',
      },
      {
        title: 'Vendors',
        key: 'appsSetting',
        icon: 'fe fe-user-check',
        roles: ['ADMIN', 'ERPADMIN'],
        url: '/apps/vendors',
      },
      {
        title: 'Purchase Entry',
        key: 'appsSetting',
        icon: 'fe fe-shopping-bag',
        roles: ['ADMIN', 'ERPADMIN'],
        url: '/apps/purchaseentry',
      },
      {
        title: 'Internal Transfer',
        key: 'apps',
        icon: 'fe fe-truck',
        roles: ['ADMIN', 'ERPADMIN'],
        children: [
          {
            title: 'To Order',
            key: 'appsInternalTransfer',
            url: '/apps/internaltransfer',
          },
          {
            title: 'To Receive',
            key: 'appsReceiveOrders',
            url: '/apps/ReceiveOrders',
          },
          {
            title: 'To Dispatch',
            key: 'appsDispatch',
            url: '/apps/dispatch',
          },
          {
            title: 'Dispatch Items',
            key: 'appsDispatchItem',
            url: '/apps/DispatchItem',
          },
        ],
      },
      {
        title: 'Maintenance',
        key: 'apps',
        icon: 'fa fa-server',
        roles: ['ADMIN', 'ERPADMIN'],
        children: [
          {
            title: 'Assets',
            key: 'appsAssets',
            url: '/apps/asset',
          },
          {
            title: 'Asset Types',
            key: 'appsAssettypes',
            url: '/apps/assettypes',
          },
          {
            title: 'Maintainence Bill Types',
            key: 'appsMaintbilltypes',
            url: '/apps/maintainencebilltypes',
          },
        ],
      },
      {
        title: 'Master Data',
        key: 'apps',
        icon: 'fa fa-star',
        roles: ['ADMIN', 'ERPADMIN'],
        children: [
          {
            title: ' Location',
            key: 'appslocation',
            url: '/apps/location',
          },
        ],
      },
      {
        title: 'Finance',
        key: 'apps',
        icon: 'fa fa-credit-card',
        roles: ['ADMIN', 'ERPADMIN'],
        children: [
          {
            title: ' Bankaccount',
            key: 'appsBankaccount',
            url: '/apps/bankaccount',
          },
          {
            title: 'Credit',
            key: 'appsCredit',
            url: '/apps/credit',
          },
          {
            title: 'Purchase/Maintainence',
            key: 'appsPurchaseMaint',
            url: '/apps/purchasemaint',
          },
          {
            title: 'Bills Pay byVendor',
            key: 'appsBillbyvendor',
            url: '/apps/billbyvendor',
          },
        ],
      },
    ],
  },
  {
    title: 'Feedback',
    icon: 'fa fa-heart',
    roles: [],
    key: 'extraAppsWordpressAdd',
    url: '/apps/wordpress-add',
  },
  {
    title: 'About Us',
    icon: 'fe fe-alert-octagon',
    roles: [],
    key: 'extraAppsHelpdeskDashboard',
    url: '/apps/helpdesk-dashboard',
  },
  {
    title: 'Report Management',
    key: 'apps',
    icon: 'fa fa-file',
    roles: ['ADMIN', 'ERPADMIN'],
    children: [
      {
        title: 'OrderWise',
        // icon: 'fa fa-stack-overflow',
        roles: ['ADMIN', 'ERPADMIN'],
        key: 'appsorderwise',
        url: '/apps/orderwise',
      },
    ],
  },
]
